<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Withdraws </span>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="withdraws"
      item-key="id"
      class="table-rounded row-pointer"
      :single-expand="true"
      show-expand
      :loading="loading"
      :search="search"
      disable-sort
      :server-items-length="pagination.total"
      :page-count="pagination.last_page"
      :options.sync="options"
      :page.sync="pagination.current_page"
      :items-per-page="pagination.per_page"
      :footer-props="{
        'items-per-page-options': [5, 10, 50, 100],
      }"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card class="mx-auto">
            <v-card-title></v-card-title>
            <v-card-subtitle class="pb-0">
              {{ item.user.name }}
              {{ item.user.email }}
            </v-card-subtitle>
            <v-card-text>
              {{ item.user.phone }}
            </v-card-text>
            <v-divider class="mx-4"></v-divider>

            <v-card-title>Payment method details</v-card-title>
            <v-list>
              <v-list-item v-for="(value, name) in item.paymentmethod.details" :key="name">
                <v-list-item-title>{{ name }}: {{ value }}</v-list-item-title>
              </v-list-item>
            </v-list>

            <v-card-actions v-if="item.status === 'pending'">
              <v-btn v-if="$can('update withdraws')" color="primary" text @click="approveWithdraw(item.id)">
                Approve
              </v-btn>

              <v-btn v-if="$can('update withdraws')" color="error" text @click="rejectWithdraw(item.id)">
                Reject
              </v-btn>
            </v-card-actions>
            <template v-for="error in errors">
              <v-alert :key="error" :value="true" type="error" class="mb-3">
                <small> {{ error[0] }}</small>
              </v-alert>
            </template>
          </v-card>
        </td>
      </template>
      <template #[`item.amount`]="{item}">
        <span>
          {{ Intl.NumberFormat().format(item.amount) }}
        </span>
      </template>
      <template #[`item.status`]="{item}">
        <v-chip :color="color(item.status)" dark>
          {{ item.status }}
        </v-chip>
      </template>
      <template #[`item.paymentmethod`]="{item}">
        <div class="d-flex flex-column">
          {{ item.paymentmethod.type }}
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  setup() {
    return {
      headers: [
        { text: 'AMOUNT', value: 'amount' },
        { text: 'STATUS', value: 'status' },
        { text: 'USER', value: 'user.name' },
        { text: 'PAYMENT METHOD', value: 'paymentmethod' },
        { text: 'DATE', value: 'created_at' },
        { text: '', value: 'data-table-expand' },
      ],
      color(status) {
        if (status === 'pending') {
          return 'warning'
        }
        if (status === 'rejected') {
          return 'error'
        }

        return 'success'
      },
    }
  },
  data() {
    return {
      withdraws: [],
      loading: true,
      search: '',
      errors: {},
      options: {},
      pagination: {},
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
    },
    deep: true,
  },
  created() {
    this.fetchApiData()
  },
  methods: {
    setErrors(res) {
      this.errors = res.data.errors // Object.fromEntries(res.data.errors.map(item => [item.field, item.msg]))
    },
    approveWithdraw(withdrawId) {
      this.loading = true

      // hit endpoint for completing withdraw
      this.$http
        .post(`/withdraws/${withdrawId}/complete`)
        .then(res => {
          console.log(res)
          this.fetchApiData()
        })
        .then(null, err => {
          this.setErrors(err.response)
          this.loading = false
        })
    },
    rejectWithdraw(withdrawId) {
      this.loading = true

      // hit endpoint for completing withdraw
      this.$http
        .post(`/withdraws/${withdrawId}/reject`)
        .then(res => {
          console.log(res)
          this.fetchApiData()
        })
        .then(null, err => {
          this.setErrors(err.response)
          this.loading = false
        })
    },
    fetchApiData() {
      this.loading = true
      const { page, itemsPerPage } = this.options
      this.$http
        .get(`/withdraws?page=${page}&per_page=${itemsPerPage}`)
        .then(res => {
          this.withdraws = res.data.data
          this.pagination = res.data.meta
        })
        .finally(() => {
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
